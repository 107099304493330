import React, { Component } from 'react'
import Link from '../utils/link'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <Link to='/' title='Visual Thing' className='footer__logo'>
              VISUAL THING
            </Link>
          </div>
          <div className='footer__col'>
            <Link to='//'>
              45 Cubitt St, Cremorne <br />
              VICTORIA, AUSTRALIA
            </Link>
          </div>
          <div className='footer__col'>
            <Link to='mailto:connect@visualthing.com.au'>
              CONNECT@VISUALTHING.COM.AU
            </Link>
            <Link to='tel:+61394214866'>
              +613 9421 4866 
            </Link>
          </div>
          <div className='footer__col'>
            <Link to='https://www.instagram.com/visualthingstudio/'>
              INSTAGRAM
            </Link>
            <Link to='https://au.linkedin.com/company/visualthing'>
              LINKEDIN
            </Link>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
